import UserSettingsContainer from './components/Header/FirstMenu/UserSettings/UserSettingsContainer';
import { useEffect } from 'react';
import { useNotifications } from './hooks/useNotifications';
import { useNotificationSubscriptions } from 'src/hooks/useNotificationSubscriptions';

import AudioBarContainer from 'src/widgets/AudioBar/AudioBarContainer';
import NotificationsWidget from 'src/widgets/Notifications/Notifications';
import OpenAIDrawer from 'src/widgets/OpenAIDrawer/OpenAIDrawer';
import { refreshPushNotificationToken } from './config/firebase';
import { useUser as useUserStore } from './store';

const Root = () => {
  const user = useUserStore();
  useNotificationSubscriptions();
  useNotifications({});

  useEffect(() => {
    if (!user?.pushNotificationToken) return;
    // validate token for push notifications
    refreshPushNotificationToken();
  }, [user?.pushNotificationToken]);

  return (
    <>
      <AudioBarContainer />
      <NotificationsWidget />
      <UserSettingsContainer />
      <OpenAIDrawer />
    </>
  );
};

export default Root;
