import FullCalendar from '@fullcalendar/react';
import React, { useState, useRef, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, Icon } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useTask } from 'src/hooks/useTask';

import CalendarEvent from './CalendarEvent';
import Header from './Header/Header';
import { DayCellContentArg } from '@fullcalendar/core';
import enLocale from '@fullcalendar/core/locales/en-au';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import timeGridPlugin from '@fullcalendar/timegrid';
import { addDays } from 'date-fns';
import { useUserWorkspace } from 'src/store';

import { useGetTasksQuery } from 'src/generated';

import { FullCalendarBlock, AddTaskButton, DayCell } from './styles';

// https://github.com/fullcalendar/fullcalendar-examples/blob/main/react/src/DemoApp.jsx
const Calendar = () => {
  const workspaceId = useUserWorkspace();
  const { id: projectId } = useParams<{ id: string }>();

  const { data } = useGetTasksQuery({
    variables: {
      workspaceId,
      projectId,
    },
  });

  const { onTaskOpen, onCreateTask } = useTask();

  const theme = useTheme();

  const calendarRef = useRef<FullCalendar>(null);

  const [currentDate, setCurrentDate] = useState();
  const [isWeekendsVisible, setIsWeekendsVisible] = useState(true);

  const handleDatesSet = (dateInfo: any) => {
    setCurrentDate(dateInfo);
  };

  const handleTaskClick = (clickInfo: any) => {
    onTaskOpen({ taskId: clickInfo.event.id });
  };

  const calendarTasks = useMemo(() => {
    return data?.getTasks.map(
      ({ _id, title, dueDate, startDate, labels, assignee, ...rest }) => ({
        id: _id,
        start: (startDate ? startDate : dueDate) as unknown as Date,
        end: dueDate ? addDays(new Date(dueDate), 1) : undefined,
        allDay: true,
        backgroundColor: labels[0]?.color || theme.color.navi,
        title,
        assignee,
        ...rest,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getTasks]);

  const renderDayCellContent = useCallback((args: DayCellContentArg) => {
    const { date, dayNumberText, isToday } = args;
    return (
      <Flex gap={10} alignItems="center">
        <DayCell isToday={isToday}>{dayNumberText}</DayCell>
        <AddTaskButton
          icon={<Icon name="plus" />}
          onClick={() => {
            onCreateTask({
              title: 'Task created at ' + new Date().toLocaleDateString(),
              dueDate: date,
            });
          }}
        />
      </Flex>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDayTaskContent = useCallback(({ event }) => {
    const eventJson = event.toPlainObject();

    return <CalendarEvent event={eventJson} />;
  }, []);

  return (
    <>
      <FullCalendarBlock>
        <Header
          calendarRef={calendarRef}
          isWeekendsVisible={isWeekendsVisible}
          setIsWeekendsVisible={setIsWeekendsVisible}
          currentDate={currentDate}
        />

        <FullCalendar
          height={window.innerHeight - 138}
          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            multiMonthPlugin,
          ]}
          headerToolbar={false}
          initialView="multiMonthYear"
          editable={false}
          // selectable
          nowIndicator
          selectMirror
          dayMaxEvents={false}
          // dayMaxEvents={20}
          weekends={isWeekendsVisible}
          events={calendarTasks}
          locale={enLocale}
          eventClick={handleTaskClick}
          eventContent={renderDayTaskContent}
          datesSet={handleDatesSet}
          dayCellContent={renderDayCellContent}
          // eventMinHeight={42}
          selectable={false}
          eventOrderStrict
          handleWindowResize={false}
          multiMonthMaxColumns={1}
        />
      </FullCalendarBlock>
    </>
  );
};

export default React.memo(Calendar);
