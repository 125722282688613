import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Icon,
  Menu,
  useConfirm,
} from 'src/components/design-system';
import { useDrawerStore, DRAWER_IDS } from 'src/store';
import { useTask } from 'src/hooks/useTask';
import { useTaskTemplate } from 'src/hooks/useTaskTemplate';

import { TASK_MORE_MENU } from './constants';
import Dropdown from 'rc-dropdown';
import { openTaskDescriptionHistoryDrawer } from 'src/graphql/vars';
import { useUpdateIsOpenTaskTemplateModal } from 'src/store';

const TaskMoreMenu = ({ action }) => {
  const { open } = useDrawerStore(DRAWER_IDS.TASK_ADVANCED_FIELDS);
  const confirm = useConfirm();
  const openTaskTemplateModal = useUpdateIsOpenTaskTemplateModal();
  const {
    _id: taskId,
    workspaceId,
    title,
    description,
    storyPoints,
    startDate,
    priority,
    isUrgent,
    dueDate,
    assignee,
  } = action;

  const { t } = useTranslation();
  const { onDuplicateTask, onCopyShareLink, onUpdateTask } = useTask();
  const { onCreateTaskTemplate } = useTaskTemplate();
  const handleMenuClick = (id: string) => {
    switch (id) {
      case TASK_MORE_MENU.MARK_URGENT:
        onUpdateTask({ taskId, isUrgent: !isUrgent });
        break;
      case TASK_MORE_MENU.ADVANCED_FIELDS:
        open();
        break;
      case TASK_MORE_MENU.DESCRIPTION_HISTORY:
        openTaskDescriptionHistoryDrawer(true);
        break;
      case TASK_MORE_MENU.DUPLICATE_TASK:
        onDuplicateTask({ taskId });
        break;
      case TASK_MORE_MENU.COPY_SHARE_LINK:
        onCopyShareLink();
        break;
      case TASK_MORE_MENU.SAVE_AS_TEMPLATE:
        confirm({
          message: 'Do you want to save this task as a template?',
        })
          .then(() => {
            onCreateTaskTemplate({
              workspaceId,
              title,
              description,
              storyPoints,
              startDate,
              priority,
              isUrgent,
              dueDate,
              assignee: assignee?._id,
            });
          })
          .catch(() => {});

        break;
      case TASK_MORE_MENU.APPLY_TEMPLATE:
        openTaskTemplateModal(true);
        break;
      default:
        break;
    }
  };

  const taskMoreMenuOptions = useMemo(() => {
    return [
      {
        type: 'header',
        title: 'task.moreMenu.markAs',
      },
      {
        id: TASK_MORE_MENU.MARK_URGENT,
        title: isUrgent
          ? 'task.moreMenu.markNotUrgent'
          : 'task.moreMenu.markUrgent',
        icon: 'info-triangle-filled',
      },
      {
        type: 'divider',
      },
      {
        type: 'header',
        title: 'task.moreMenu.advancedFieldsTitle',
      },
      {
        id: TASK_MORE_MENU.ADVANCED_FIELDS,
        title: 'task.moreMenu.advancedFields',
        icon: 'info',
      },
      {
        type: 'divider',
      },
      {
        type: 'header',
        title: 'task.moreMenu.taskTemplates',
      },
      {
        id: TASK_MORE_MENU.APPLY_TEMPLATE,
        title: 'task.moreMenu.applyTemplate',
        // icon: 'puzzle',
      },
      {
        id: TASK_MORE_MENU.SAVE_AS_TEMPLATE,
        title: 'task.moreMenu.saveAsTemplate',
        // icon: 'puzzle',
      },
      {
        type: 'divider',
      },
      {
        id: TASK_MORE_MENU.DUPLICATE_TASK,
        title: 'task.moreMenu.duplicateTask',
        icon: 'copy',
      },
      {
        id: TASK_MORE_MENU.DESCRIPTION_HISTORY,
        title: 'task.moreMenu.descriptionHistory',
        icon: 'history',
      },
      {
        id: TASK_MORE_MENU.COPY_SHARE_LINK,
        title: 'task.moreMenu.copyShareLink',
        icon: 'share',
      },
    ];
  }, [isUrgent]);

  return (
    <>
      <Dropdown
        placement="bottomRight"
        trigger={['click']}
        overlay={() => (
          <Menu
            options={taskMoreMenuOptions}
            handleMenuClick={handleMenuClick}
          />
        )}
        getPopupContainer={(trigger: HTMLElement) =>
          trigger?.parentNode as HTMLElement
        }
      >
        <Button size="little" htmlType="text">
          <Flex padding={[0, 5, 0, 0]}>
            <Icon name="dots-vertical" size={20} />
            {t('general.more')}
          </Flex>
        </Button>
      </Dropdown>
    </>
  );
};

export default TaskMoreMenu;
