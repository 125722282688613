import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { UpdateUserDocument } from '../generated';
import GQLClient from '../gql-client';

export const firebaseConfig = {
  apiKey: 'AIzaSyCA2Q5ARP0FpMaVe2sC5-n3etHqbz7pS3o',
  authDomain: 'manage-app-6aa8e.firebaseapp.com',
  projectId: 'manage-app-6aa8e',
  storageBucket: 'manage-app-6aa8e.appspot.com',
  messagingSenderId: '88913233810',
  appId: '1:88913233810:web:3d850026bc3064229f234b',
  measurementId: 'G-ZZ1KXCMPSV',
};

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(registration => {
      console.log('Service Worker registered successfully:', registration);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}

export const FIREBASE_VAPID_KEY =
  'BLWpSB4HDXjwlYjtq8zd2BxkGJhnVsSoIPzowt99G0cN6wWXL1_ficZcjqznzMYxXkhL2doeppzRf2LJ7TQUKFs';

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
  const permission = await Notification.requestPermission();

  if (permission !== 'granted') {
    console.error('Permission not granted');
    return null;
  }

  const token = await getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY });
  if (!token) {
    console.error('No token received');
    return null;
  }

  return token;
};

export const refreshPushNotificationToken = async () => {
  const token = await requestForToken();

  if (!token) {
    return;
  }

  await GQLClient.mutate({
    mutation: UpdateUserDocument,
    variables: {
      pushNotificationToken: token,
    },
  });
};

onMessage(messaging, ({ notification }) => {
  console.log('notification', notification);
  new Notification(notification.title, {
    body: notification.body,
    silent: true,
    timestamp: Date.now(),
  });
});
