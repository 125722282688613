import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useState } from 'react';

import Editor from 'manage-ui-ckeditor5';

import * as Styled from './styles';

const ProjectDescription = () => {
  const [value, setValue] = useState('');

  return (
    <Styled.Container>
      <h2>{'Project description'}</h2>
      <Styled.EditorBlock>
        <CKEditor
          config={{
            placeholder: 'What is this project about?',
          }}
          editor={Editor}
          data={value}
          onReady={() => {}}
          onChange={(_, editor) => {
            const data = editor.getData();
            setValue(data);
          }}
          onBlur={() => {}}
          onFocus={() => {}}
        />
      </Styled.EditorBlock>
    </Styled.Container>
  );
};

export default ProjectDescription;
