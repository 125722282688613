import React from 'react';

import DisplayStatusDropdown from './DisplayStatusDropdown';
import EditStatusDropdown from './EditStatusDropdown';

const StatusDropdown = ({
  id,
  rowIndex,
  taskId,
  statusId,
  isEditing,
  updateData,
}) => {
  if (isEditing) {
    return (
      <EditStatusDropdown
        id={id}
        rowIndex={rowIndex}
        taskId={taskId}
        statusId={statusId}
        updateData={updateData}
      />
    );
  }

  return <DisplayStatusDropdown statusId={statusId} />;
};

export default StatusDropdown;
