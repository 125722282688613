import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: -webkit-fill-available;
`;

export const Header = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ImageBlock = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit scale-down;
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

export const FileInfoDate = styled.div`
  font-size: 12px;
`;
