import React, { useEffect, useState } from 'react';

import styled, { keyframes } from 'styled-components';

interface SnowflakeProps {
  size: string;
  leftIni: string;
  leftEnd: string;
  left: string;
  duration: string;
  delay: string;
  hasBlur: boolean;
}

const snowfallAnimation = (leftIni: string, leftEnd: string) => keyframes`
  0% {
    transform: translateY(0) translateX(${leftIni});
    opacity: 0.5;
  }
  50% {
    transform: translateY(50vh) translateX(${leftEnd});
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) translateX(${leftIni});
    opacity: 0.5;
  }
`;

const SnowfallContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
`;

const Snowflake = styled.div<SnowflakeProps>`
  position: fixed;
  top: -5vh;
  width: ${props => props.size};
  height: ${props => props.size};
  background: ${({ theme }) => theme.snowflake || 'white'};
  border-radius: 50%;
  left: ${props => props.left};
  animation: ${props => snowfallAnimation(props.leftIni, props.leftEnd)}
    ${props => props.duration} linear infinite;
  animation-delay: ${props => props.delay};
  filter: ${props => (props.hasBlur ? 'blur(1px)' : 'none')};
`;

const random = (max: number): number => Math.random() * max;

const generateSnowflakes = (): SnowflakeProps[] => {
  return Array.from({ length: 150 }, (_, index) => ({
    size: `${Math.max(0.1, random(5) * 0.05)}vw`,
    leftIni: `${random(20) - 10}vw`,
    leftEnd: `${random(20) - 10}vw`,
    left: `${random(100)}vw`,
    duration: `${20 + random(10)}s`,
    delay: `${-random(20)}s`,
    hasBlur: (index + 1) % 6 === 0,
  }));
};

const Snowfall: React.FC = () => {
  const [snowflakes, setSnowflakes] = useState<SnowflakeProps[]>([]);

  useEffect(() => {
    setSnowflakes(generateSnowflakes());
  }, []);

  return (
    <SnowfallContainer>
      {snowflakes.map((flake, index) => (
        <Snowflake
          key={index}
          size={flake.size}
          left={flake.left}
          duration={flake.duration}
          delay={flake.delay}
          leftIni={flake.leftIni}
          leftEnd={flake.leftEnd}
          hasBlur={flake.hasBlur}
        />
      ))}
    </SnowfallContainer>
  );
};

export default Snowfall;
