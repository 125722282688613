import { useEffect, useState, useRef, useCallback } from 'react';

type UseIntersectionObserverOptions = {
  rootMargin?: string;
  threshold?: number | number[];
};

type UseIntersectionObserverReturn = {
  isIntersecting: boolean;
  ref: React.RefObject<HTMLDivElement>;
};

function useIntersectionObserver(
  callback: IntersectionObserverCallback,
  options: UseIntersectionObserverOptions = {},
): UseIntersectionObserverReturn {
  const { rootMargin = '0px', threshold = 0 } = options;

  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null); // Correct usage of useRef

  const observerCallback: IntersectionObserverCallback = useCallback(
    (entries, observer) => {
      entries.forEach(entry => {
        setIsIntersecting(entry.isIntersecting);

        if (entry.isIntersecting) {
          callback(entries, observer);
        }
      });
    },
    [callback],
  );

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(observerCallback, {
        rootMargin,
        threshold,
      });

      observer.observe(ref.current);

      return () => {
        if (ref.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          observer.unobserve(ref.current);
        }
      };
    }
  }, [observerCallback, rootMargin, threshold]);

  return { ref, isIntersecting };
}

export default useIntersectionObserver;
