import {
  useCreateTaskAdvancedFieldMutation,
  useUpdateTaskAdvancedFieldMutation,
  useDeleteTaskAdvancedFieldMutation,
  MutationUpdateTaskAdvancedFieldArgs,
  MutationDeleteTaskAdvancedFieldArgs,
  MutationCreateTaskAdvancedFieldArgs,
} from 'src/generated';
import { useNotification } from '../components/design-system';

export const useTaskAdvancedFields = () => {
  const notification = useNotification();
  const [createTaskAdvancedField] = useCreateTaskAdvancedFieldMutation();
  const [updateTaskAdvancedField] = useUpdateTaskAdvancedFieldMutation();
  const [deleteTaskAdvancedField] = useDeleteTaskAdvancedFieldMutation();

  const onCreateTaskAdvancedField = async ({
    taskId,
    title,
    type,
  }: MutationCreateTaskAdvancedFieldArgs) => {
    await createTaskAdvancedField({
      variables: {
        taskId,
        title,
        type,
      },
      refetchQueries: ['GetAction'],
    });
    notification.show({
      title: 'Task advanced field created',
      message: 'Task advanced field has been created successfully',
      variant: 'success',
    });
  };

  const onUpdateTaskAdvancedField = async ({
    fieldId,
    value,
  }: MutationUpdateTaskAdvancedFieldArgs) => {
    await updateTaskAdvancedField({
      variables: {
        fieldId,
        value,
      },
    });
    notification.show({
      title: 'Completed!',
      message: 'Advanced field has been updated successfully',
      variant: 'success',
    });
  };

  const onDeleteTaskAdvancedField = async ({
    fieldId,
  }: MutationDeleteTaskAdvancedFieldArgs) => {
    await deleteTaskAdvancedField({
      variables: {
        fieldId,
      },
      update(cache, { data }) {
        console.log('data', data);
        cache.evict({ id: `TaskAdvancedField:${fieldId}` });
        cache.gc();
      },
    });
    notification.show({
      title: 'Completed!',
      message: 'Advanced field has been deleted successfully',
      variant: 'success',
    });
  };

  return {
    onCreateTaskAdvancedField,
    onUpdateTaskAdvancedField,
    onDeleteTaskAdvancedField,
  };
};
