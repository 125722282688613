import { Button } from 'src/components/design-system';
import styled from 'styled-components';

type ConfirmButtonProps = {
  background: string;
};

export const ConfirmButton = styled(Button)<ConfirmButtonProps>`
  flex: 1;
  padding: 15px 20px;
  border-radius: 0;
  font-weight: bold;
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background: ${({ background }) => background};
  }
`;
