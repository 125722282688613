import React from 'react';
import { TaskAdvancedFieldTypeEnum } from 'src/generated';
import SpreadsheetField from './SpreadsheetField';

const TaskAdvancedFields = ({ action }) => {
  const { taskAdvancedFields } = action;

  // Function to render different UI based on the switch state
  const renderContent = taskAdvancedField => {
    const { type } = taskAdvancedField;
    switch (type) {
      case TaskAdvancedFieldTypeEnum.Spreadsheet:
        return <SpreadsheetField taskAdvancedField={taskAdvancedField} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {taskAdvancedFields.map(taskAdvancedField => {
        return (
          <div key={taskAdvancedField._id}>
            {renderContent(taskAdvancedField)}
          </div>
        );
      })}
    </div>
  );
};

export default TaskAdvancedFields;
