import { gql } from '@apollo/client';

export const TASK_FRAGMENT = gql`
  fragment TaskFragment on Action {
    _id
    workspaceId
    title
    priority
    storyPoints
    description
    dueDate
    startDate
    statusId
    createdAt
    updatedAt
    completedAt
    rank
    totalSubtasksCount
    isUrgent
    isArchived
    projectId {
      _id
      color
    }
    assignee {
      _id
      email
      fullName
      profilePicture
    }
    parentId {
      _id
      title
    }
    attachments {
      _id
      url
      previewImage
      size
      type
      fileName
      createdAt
    }
    labels {
      _id
      title
      color
    }
    taskAdvancedFields {
      _id
      type
      title
      value
    }
  }
`;
