import { Box } from 'src/components/design-system';
import { useTaskAdvancedFields } from 'src/hooks/useTaskAdvancedFields';
import { TaskAdvancedFieldTypeEnum } from 'src/generated';
import { useQueryParams } from 'src/hooks/useQueryParams';
import AdvancedField from './AdvancedField';

const ADVANCED_FIELDS = [
  {
    title: 'Create a new spreadsheet',
    img: 'https://files.grawdanin.com/openart-image_9b8xxbKq_1737212890463_raw.jpg',
    type: TaskAdvancedFieldTypeEnum.Spreadsheet,
  },
];

const TaskAdvancedFields = () => {
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;
  const { onCreateTaskAdvancedField } = useTaskAdvancedFields();

  const handleCreateAdvancedField = title => {
    onCreateTaskAdvancedField({
      taskId,
      title,
      type: TaskAdvancedFieldTypeEnum.Spreadsheet,
    });
  };

  return (
    <Box padding={20}>
      {ADVANCED_FIELDS.map(field => (
        <AdvancedField {...field} onCreate={handleCreateAdvancedField} />
      ))}
    </Box>
  );
};

export default TaskAdvancedFields;
