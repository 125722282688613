import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Box, Icon } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useTask } from 'src/hooks/useTask';

import { useProject } from 'src/helpers/apollo';

import Attachments from '../../Board/Action/Attachements/Attachments';
import AttachmentsTitle from '../../Board/Action/Attachements/AttachmentsTitle';
import Comments from '../../Board/Action/Comments/Comments';
import Labels from '../../Board/Action/Labels/Labels';
import ProjectLabels from '../../Board/Action/Labels/ProjectLabels';
import SubTasks from '../../Board/Action/SubTasks/SubTasksContainer';
import TaskActions from '../../Board/Action/TaskActions/TaskActions';
import TaskCustomFields from '../../Board/Action/TaskCustomFields/TaskCustomFields';
import TaskDescription from '../../Board/Action/TaskDescription/TaskDescription';
import TaskAdvancedFields from '../../Board/Action/TaskAdvancedFields/TaskAdvancedFields';
import TaskHistory from '../../Board/Action/TaskHistory/TaskHistory';
import TaskParent from '../../Board/Action/TaskParent/TaskParent';
import TimeTracking from '../../Board/Action/TimeTracking/TimeTracking';
import Title from '../../Board/Action/Title/Title';
import TaskBlock from '../TaskBlock/TaskBlock';

import * as Styled from './styles';

const TaskModal = ({ action }) => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const { project } = useProject();

  const {
    _id,
    title,
    attachments,
    statusId,
    parentId,
    isUrgent,
    taskAdvancedFields,
  } = action;

  const { completedStatusId, unstagedStatusId } = project;

  const { onUpdateTask } = useTask();

  const handleCompleteTask = () => {
    if (statusId === completedStatusId) {
      return onUpdateTask({
        taskId: _id,
        statusId: unstagedStatusId,
      });
    }
    onUpdateTask({ taskId: _id, statusId: completedStatusId });
  };

  useEffect(() => {
    const scrollContainer = containerRef.current;

    if (!scrollContainer) return;
    const handleScroll = () => {
      if (scrollContainer) {
        const { scrollTop } = scrollContainer;
        setIsHeaderFixed(scrollTop > 5);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styled.Right ref={containerRef}>
      <Styled.RightHeader isHeaderFixed={isHeaderFixed}>
        <TaskActions
          action={action}
          handleCompleteTask={handleCompleteTask}
          isHeaderFixed={isHeaderFixed}
        />
      </Styled.RightHeader>
      <Styled.RightTitleBlock isHeaderFixed={isHeaderFixed}>
        {parentId && <TaskParent {...parentId} />}
        <Flex alignItems="flex-start" gap={10}>
          {isUrgent && (
            <Box>
              <Icon
                size={22}
                name="info-triangle-filled"
                color={theme.color.urgent}
              />
            </Box>
          )}
          <Title taskId={_id} title={title} />
        </Flex>
      </Styled.RightTitleBlock>

      <TaskBlock title={t('task.description.title')} icon="file-text">
        <TaskDescription action={action} />
      </TaskBlock>

      {taskAdvancedFields.length > 0 && (
        <TaskBlock title={t('task.detail.advancedFields')} icon="info">
          <TaskAdvancedFields action={action} />
        </TaskBlock>
      )}

      <TaskBlock title={t('task.detail.subtasks')} icon="subtasks">
        <SubTasks action={action} />
      </TaskBlock>
      <TaskBlock
        title={t('task.labels.title')}
        icon="tags"
        suffix={<ProjectLabels action={action} />}
      >
        <Labels action={action} />
      </TaskBlock>

      <Box padding={[0, 20]} margin={[0, 0, 50, 0]}>
        <AttachmentsTitle taskId={_id} />
        <Attachments id={_id} attachments={attachments} />
      </Box>

      <TaskBlock title={t('task.detail.customFields')} icon="custom-fields">
        <TaskCustomFields action={action} />
      </TaskBlock>

      <TaskBlock>
        <TimeTracking action={action} />
      </TaskBlock>

      <TaskBlock title={t('task.detail.comments')} icon="comments">
        <Comments taskId={_id} />
      </TaskBlock>

      <TaskBlock title={t('task.detail.history')}>
        <TaskHistory />
      </TaskBlock>
    </Styled.Right>
  );
};

export default TaskModal;
