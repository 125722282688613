import { useMemo, useRef } from 'react';
import { HotTable, HotTableRef } from '@handsontable/react-wrapper';
import { HyperFormula } from 'hyperformula';
import { registerAllModules } from 'handsontable/registry';
import { useTaskAdvancedFields } from 'src/hooks/useTaskAdvancedFields';
import { Flex, Button } from 'src/components/design-system';
import { useReactiveVar } from '@apollo/client';
import { currentThemeVar } from 'src/graphql/vars';
import { THEME } from 'src/styles/theme/types';

import 'handsontable/styles/handsontable.css';
import 'handsontable/styles/ht-theme-horizon.css';

// register Handsontable's modules
registerAllModules();

// Function to create a 10x10 matrix (10 rows and 10 columns)
function createMatrix(rows, columns) {
  return Array.from({ length: rows }, () => Array(columns));
}

const SpreadsheetField = ({ taskAdvancedField }) => {
  const theme = useReactiveVar(currentThemeVar);
  const { _id, title, value } = taskAdvancedField;
  const hotRef = useRef<HotTableRef>(null);
  const { onUpdateTaskAdvancedField, onDeleteTaskAdvancedField } =
    useTaskAdvancedFields();

  const hyperformulaInstance = HyperFormula.buildEmpty({
    licenseKey: 'internal-use-in-handsontable',
  });

  const defaultValue = useMemo(() => {
    return value ? JSON.parse(value) : createMatrix(10, 4);
  }, [value]);

  return (
    <>
      <Flex alignItems="center">
        <h2>{title}</h2>
        <Flex pushRight gap={10}>
          <Button
            onClick={async () => {
              const hot = hotRef.current?.hotInstance;
              const data = hot?.getSourceData();
              console.log('data', data);
              await onUpdateTaskAdvancedField({
                fieldId: _id,
                value: JSON.stringify(data),
              });
            }}
          >
            {'Save changes'}
          </Button>
          <Button
            onClick={async () => {
              await onDeleteTaskAdvancedField({ fieldId: _id });
            }}
          >
            {'Delete'}
          </Button>
        </Flex>
      </Flex>

      <div
        className={
          theme === THEME.LIGHT ? 'ht-theme-horizon' : 'ht-theme-horizon-dark'
        }
      >
        <HotTable
          ref={hotRef}
          data={defaultValue}
          formulas={{
            engine: hyperformulaInstance,
            sheetName: `Sheet${_id}`,
          }}
          afterChange={async (changes, source) => {
            if (source === 'loadData') return;
            // console.log('changes', changes);
          }}
          comments
          search
          stretchH={'all'}
          wordWrap={false}
          dropdownMenu
          contextMenu
          manualColumnResize
          manualRowResize
          rowHeaders={true}
          colHeaders={true}
          height="auto"
          width="100%"
          autoWrapRow={true}
          autoWrapCol={true}
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
        />
      </div>
    </>
  );
};

export default SpreadsheetField;
